.banner{
    width: 100%;
    position: relative;
    background-image: url(../../asset//images/background-banner.jpeg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px 0;
    .image {
        display: flex;
        align-items: center;
        img{
            height: 100%;
            width: auto;
            max-height: 500px;
            @media screen and (max-width:1025px){
                height: auto;
            }
            
        }
        
    }
    .grid{
        @media screen and (max-width:767px){
            display: block;
            div {
                width: 100%;
                justify-content: center;
            }
        } 
    }
    .content{
        display: flex;
        align-items: center;
        h2{
            font-size: 60px;
            color: #fea724;
            line-height: 50px;
            @media screen and (max-width:1025px) and (min-width: 769px) {
                font-size: 50px;
            }
            @media screen and (max-width:768px){
                font-size: 40px;
            }
        }
        h3{
            font-size: 40px;
            @media screen and (max-width:1025px) and (min-width: 631px) {
                font-size: 30px;
            }
            @media screen and (max-width:630px){
                font-size: 26px;
            }
        }
        h2, h3{
            text-transform: uppercase;
        }
        .line{
            width: 100%;
            height: 4px;
            background: rgb(254,167,36);
            background: linear-gradient(90deg, rgba(254,167,36,1) 0%, rgba(0,0,0,1) 100%);
        }
        p{
            margin-top: 20px;
            font-family: UVNNhatKy_R;
            @media screen and (max-width:630px) and (min-width: 500px) {
                font-size: 13px;
            }
        }
        
        button{
            text-transform: uppercase;
            border: 2px solid #fea724;
            padding: 10px 40px;
            color: #fea724;
            margin-top: 20px;

            &:hover{
                cursor: pointer;
                background: #fea724;
                color: #fff;
            }

        }
    }
}