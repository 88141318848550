.item-procedure{
    position: relative;
    border-radius: 10px;
    box-shadow: 1px 1px 3px 3px #88888824;
    overflow: hidden;
    height: 550px;
    @media screen and (max-width: 639px) {
        margin-top: 40px;
    }

    img{
        width: auto;
        height: 100%;
    }
    .item-container{
        border-radius: 10px;
        position: absolute;
        top: 0%;
        left: 100%;
        width: 100%;
        height: 100%;
        background-color: #0000009c;
        color: #fff;
        transition: all 1s;
        padding: 20px;
    }
    .item-content-title{
        text-transform: uppercase;
        font-family: UVNVan_B;
        font-size: 28px;
    }
    &:hover{
        .item-container{
            left: 0;
            transition: all 0.5s;
        }
    }
    .item-content{
        font-size: 18px;
    }

    .item-content-title{}
}