.contact-content{
    .container {
        .image{
            @media screen and (max-width: 768px) {
                display: flex;
                align-items: center;
            }
        }
        img{
            max-height: 340px;
        }
    }
    .contact-info{
        p{
            font-size: 20px;
            font-family: UVNNhatKy_R;
            @media screen and (max-width: 768px) {
                font-size: 14px !important;
            }
        }
    }
}